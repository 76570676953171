<template>
  <div>
    <editor :key="editorKey" v-model="content" api-key="no-api-key" :init="editorInit" ref="editor" />
    <send-warning-modal v-if="warningModal" v-model="warningModal" />
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import tinymce from "tinymce/tinymce";
import "tinymce/themes/silver/theme";
import "tinymce/icons/default";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/anchor";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/wordcount";

// Plugins
import "tinymce/plugins/paste";
import "tinymce/plugins/noneditable";

import Editor from "@tinymce/tinymce-vue";
import SendWarningModal from "./SendWarningModal";

export default {
  name: "RichTextEditor",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  components: { SendWarningModal, Editor },
  data() {
    return {
      editorKey: 0,
      content: this.value,
      warningModal: false,
      currentFontSize: "5rem",

      editorInit: {
        skin_url: "/assets/tinymce/skins/ui/oxide",
        content_css: "/assets/tinymce/skins/ui/oxide/content.min.css",
        branding: false,
        height: 400,
        menubar: false,
        paste_data_images: false,
        smart_paste: false,
        elementpath: false,
        content_style:
          "html { font-size: 1vw } body {font-family: arial, helvetica, sans-serif; padding: 0 4rem;} \
          table {border-spacing: 0; width: 100%} table thead th {border-bottom: 2px solid} \
          td, th {padding: 1.5rem 1rem} \
          td {border-bottom: 1px solid;} \
          tfoot tr:first-child th, tfoot tr:last-child th, tbody tr:last-child th {border-top: 1px solid} \
          th, tr {text-align: left} \
          li {font-size: 5rem;} \
          ul, ol {padding-left: 5rem} \
          p {margin:0;}",
        fontsize_formats: "2=2rem 3=3rem 4=4rem 5=5rem 6=6rem 7=7rem 8=8rem 9=9rem 10=10rem 11=11rem 12=12rem",
        force_br_newlines: false,
        forced_root_block: "div",
        paste_postprocess: (plugin, args) => {
          this.filterInvalidNodes(args.node);
        },
        plugins: ["lists", "searchreplace visualblocks", "paste wordcount", "noneditable"],
        toolbar: ["fontsizeselect| bold italic | bullist numlist | sendWarning | customClearButton"],
        link_list: [],
        setup: (ed) => {
          if (this.content === "") {
            this.resetFontSize(ed);
          }
          ed.ui.registry.addButton("customClearButton", {
            text: 'Clear <span class="custom-clear-button-locator"></span>',
            style: `border: 2px solid black;
                    position: absolute;
                    right: 0;`,
            onAction: () => {
              this.clearEditor();
              this.resetFontSize(ed);
            },
            onSetup: () => {
              // ugly kludge, but the new TinyMCE doesn't really give us a chance to style its buttons
              const node = document.querySelector(".custom-clear-button-locator");
              if (node) {
                const parentButton = node.closest("button");
                parentButton.classList.add("clear-editor");
                const parentDiv = node.closest("div");
                parentDiv.classList.add("clear-editor-wrapper");
              }
            },
          });
          ed.ui.registry.addButton("sendWarning", {
            icon: "warning",
            tooltip: "Send warning",
            onAction: () => {
              this.showWarningModal();
            },
          });
          ed.on("keyUp", () => {
            if (ed.getContent().length === 0) {
              this.$emit("input", "");
            }
          });
          ed.on("ExecCommand", (evt) => {
            let cmd = evt.command;
            if (cmd === "FontSize" || cmd === "FontName") {
              let val = evt.value;
              let node = evt.target.selection.getNode();
              let nodeParent = node.parentNode;
              if (node.nodeName === "SPAN" && nodeParent.nodeName === "LI") {
                if (cmd === "FontSize") {
                  ed.dom.setStyle(nodeParent, "font-size", val);
                }
                if (cmd === "FontName") {
                  ed.dom.setStyle(nodeParent, "font-family", val);
                }
              } else if (node.nodeName === "UL" || node.nodeName === "OL") {
                let li = ed.dom.select("li", node);
                if (cmd === "FontSize") {
                  ed.dom.setStyle(li, "font-size", val);
                  let value = val.substring(0, val.length - 3);
                  if (value > 6) {
                    ed.dom.setStyle(li, "margin-left", value + "rem");
                  } else {
                    ed.dom.setStyle(li, "margin-left", 0);
                  }
                }
              }
            }
          });
          ed.on("ExecCommand", (evt) => {
            let cmd = evt.command;
            if (cmd === "FontSize") {
              let val = evt.value;
              this.setCurrentFontSize(val);
            }
          });
        },
      },
    };
  },
  watch: {
    content(value) {
      this.$emit("input", value);
    },
  },
  methods: {
    setCurrentFontSize(value) {
      this.currentFontSize = value.substring(0, value.length - 3);
    },
    clearEditor() {
      tinymce.activeEditor.execCommand("SelectAll");
      tinymce.activeEditor.execCommand("Delete");
    },
    resetFontSize(ed) {
      setTimeout(() => {
        ed.execCommand("FontSize", false, "5rem");
        this.currentFontSize = "5";
      }, 1000);
    },
    showWarningModal() {
      this.warningModal = true;
    },
    filterInvalidNodes(node) {
      const supportedNodesAfterPaste = [
        "p",
        "ul",
        "li",
        "ol",
        "span",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "b",
        "strong",
        "i",
        "em",
        "a",
        "div",
        "br",
        "table",
        "thead",
        "tbody",
        "tfoot",
        "tr",
        "td",
        "th",
      ];

      node.childNodes.forEach((n) => {
        // touch HTML element nodes only
        if (n.nodeType === 1) {
          // process child items first before changing the wrapper node
          this.filterInvalidNodes(n);

          // strip all images
          if (n.nodeName.toLowerCase() === "img") {
            n.parentNode.removeChild(n);
            return;
          }

          // replace unsupported wrapper nodes with supported ones, see the list above
          // also fix li > p anomaly on paste
          if (
            supportedNodesAfterPaste.indexOf(n.nodeName.toLowerCase()) === -1 ||
            (n.nodeName.toLowerCase() === "p" && n.parentNode.nodeName.toLowerCase() === "li")
          ) {
            const span = document.createElement("span");
            span.innerHTML = n.innerHTML;
            n.parentNode.replaceChild(span, n);
            return;
          }

          // strip all styling
          n.removeAttribute("style");
          n.removeAttribute("class");
          if (this.currentFontSize > 6 && (n.nodeName.toLowerCase() === "ul" || n.nodeName.toLowerCase() === "ol")) {
            n.setAttribute("style", `margin-left: ${this.currentFontSize}rem; font-size: ${this.currentFontSize}rem;`);
          } else if (n.nodeName.toLowerCase() !== "div") {
            n.setAttribute("style", `font-size: ${this.currentFontSize}rem`);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "/assets/tinymce/skins/ui/oxide/skin.min.css";
@import "/assets/tinymce/skins/ui/oxide/content.min.css";
@import "/assets/tinymce/skins/content/default/content.min.css";
.tox .tox-editor-header {
  z-index: 0 !important;
}

.tox-toolbar__group {
  border: 0 none transparent !important;
  padding: 0 0.5rem !important;
}

.tox-collection__item-label {
  line-height: initial !important;
  & * {
    font-size: initial;
    line-height: initial;
    padding: 0 2px;
  }
}

.clear-editor {
  border: 1px solid black !important;
  margin-left: auto !important;
  font-family: Roboto, sans-serif !important;
}

.clear-editor-wrapper {
  flex: 1 0 0;
}
</style>
