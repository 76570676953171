<template>
  <v-app-bar app color="white" light clipped-left>
    <router-link class="text-decoration-none" :to="toRoute">
      <v-toolbar-title style="display: flex" class="ml-0 pl-lg-10 mr-8">
        <v-img
          src="/images/logo-quadia-horizontal-w145.png"
          class="lighten-4"
          max-width="145"
          max-height="100"
        />
        <div class="ml-2 title-text">Studio Messenger</div>
      </v-toolbar-title>
    </router-link>
    <v-spacer />
    <v-sheet class="d-flex">
      <user-menu v-if="authenticated" />
    </v-sheet>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import UserMenu from "@/components/controls/app-bar/UserMenu";

export default {
  name: "AppBar",
  components: { UserMenu },
  computed: {
    ...mapState("auth", ["authenticated"]),
    toRoute() {
      return this.authenticated
        ? { name: "Home" }
        : {
            name: this.$route.name,
            params: this.$route.params,
            hash: this.$route.hash,
            query: this.$route.query,
          };
    },
  },
};
</script>

<style scoped>
.title-text {
  font-size: 1.2em;
}
</style>
