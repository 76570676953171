<template>
  <v-main class="grey lighten-4">
    <app-bar />
    <navigation-drawer v-if="authenticated" />
    <router-view />
    <snack-bar />
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import AppBar from "@/components/controls/app-bar/AppBar";
import NavigationDrawer from "@/components/controls/NavigationDrawer";
import SnackBar from "@/components/controls/SnackBar";

export default {
  name: "MainLayout",
  components: { AppBar, NavigationDrawer, SnackBar },
  computed: {
    ...mapState("auth", ["authenticated"]),
  },
};
</script>

<style scoped></style>
