import Vue from "vue";
import Vuex from "vuex";
import projectStore from "./projects";
import userStore from "./users";
import SnackbarStore from "./snackbar";
import auth from "./auth";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    projects: projectStore,
    auth: auth,
    snackbar: SnackbarStore,
    users: userStore,
  },
});
