<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          color="black"
          small
          v-on="on"
          @click="showModal"
        >
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
      <span>Delete</span>
    </v-tooltip>
    <v-dialog max-width="600" v-if="modal" v-model="modal">
      <v-card :loading="loading">
        <v-card-title>
          Delete project
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="cancel" v-on="on">mdi-close</v-icon>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <p>This will delete the following project:</p>
          <p><strong>{{ project.name }}</strong></p>
          <p>Please make sure this is not an actively running project.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="confirmDelete">Delete</v-btn>
          <v-btn color="secondary" outlined @click="cancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DeleteProject",
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      modal: false,
    };
  },
  methods: {
    ...mapActions("projects", ["deleteProject"]),
    showModal() {
      this.loading = false;
      this.modal = true;
    },
    async confirmDelete() {
      this.loading = true;
      await this.deleteProject(this.project.id);
      this.loading = false;
    },
    cancel() {
      this.modal = false;
    },
  },
};
</script>

<style scoped></style>
