<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-progress-linear indeterminate color="primary" />
          <v-card-text> Logging in... </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { $auth } from "../../services/auth";

export default {
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    if (!code || !state) {
      console.warn("Failed to log in.");
      return;
    }

    if (state !== window.sessionStorage.getItem("state")) {
      console.warn("Failed to log in.");
      return;
    }

    await $auth.askForToken(code);

    window.location.href = window.sessionStorage.getItem(
      "auth_callback_redirect_uri"
    );
  },
};
</script>
