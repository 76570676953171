<template>
  <div class="pa-6">
    <v-card v-if="!loading" class="py-5">
      <v-row no-gutters justify="center">
        <v-col cols="12" xl="5" lg="5" md="5">
          <h1 class="page-header" v-if="project">{{ project.name }}</h1>
        </v-col>
        <v-col cols="12" xl="1" lg="1" md="1"></v-col>
        <v-col cols="12" xl="5" lg="5" md="5" class="pa-1 pl-3">
          <v-tabs v-model="tab" class="tabs">
            <v-tab>Live</v-tab>
            <v-tab>Preview</v-tab>
            <v-tab>URL</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <v-col cols="12" xl="5" lg="5" md="5" sm="12">
          <rich-text-editor :key="`editor-key-${editorKey}`" v-model="text" />
          <div class="d-flex mt-12">
            <v-text-field class="pa-0" name="title" label="Message title" v-model="title"></v-text-field>
            <v-btn class="ml-4" color="primary" @click="save" :disabled="!valid || saving">Add message</v-btn>
          </div>
          <prepared-messages @pasteMessageToEditor="pasteMessageToEditor" />
        </v-col>
        <v-col cols="12" xl="1" lg="1" md="1" sm="12" class="send-column text-center">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                icon
                x-large
                fab
                tile
                :disabled="sending || !hasText"
                :loading="sending"
                v-on="on"
                @click="send"
              >
                <v-icon large> mdi-send </v-icon>
              </v-btn>
            </template>
            <span>Send to presenter</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" xl="5" lg="5" md="5" sm="12">
          <v-tabs-items v-model="tab">
            <v-tab-item :transition="false" :value="0" v-if="tab === 0">
              <preview :src="src" />
              <clear-presenter-screen />
            </v-tab-item>
            <v-tab-item :transition="false" v-if="tab === 1" :value="1">
              <preview :src="src + '?preview=1'" :value="text" ref="refreshingPreview" />
            </v-tab-item>
            <v-tab-item v-if="tab === 2" :value="2" :transition="false"><project-url /></v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
    <v-progress-linear v-else indeterminate />
  </div>
</template>

<script>
import RichTextEditor from "@/components/pages/project-settings/RichTextEditor";
import { mapActions, mapState } from "vuex";
import { toastError } from "@/services/responseErrors";
import { $http } from "../../services/http";
import { sendUrl, saveMessageUrl } from "../../services/api";
import Preview from "./project-settings/Preview";
import PreparedMessages from "@/components/pages/project-settings/PreparedMessages";
import ProjectUrl from "./project-settings/ProjectUrl";
import { CryptoService } from "../../services/cryptoService";
import { removeHtmlTags } from "../../services/contentService";
import ClearPresenterScreen from "./project-settings/ClearPresenterScreen";
export default {
  name: "Project",
  components: {
    ClearPresenterScreen,
    ProjectUrl,
    Preview,
    RichTextEditor,
    PreparedMessages,
  },
  data() {
    return {
      title: "",
      text: "",
      loading: false,
      sending: false,
      saving: false,
      tab: 0,
      editorKey: 0,
    };
  },
  watch: {
    text(value) {
      if (this.$refs.refreshingPreview) {
        this.$refs.refreshingPreview.refreshPreview(value);
      }
    },
    tab(value) {
      if (value === 1) {
        setTimeout(() => {
          if (this.$refs.refreshingPreview) {
            this.$refs.refreshingPreview.refreshPreview(this.text);
          }
        }, 2000);
      }
    },
  },
  computed: {
    ...mapState("projects", ["project"]),
    valid() {
      return this.hasText;
    },
    hasText() {
      const s = removeHtmlTags(this.text).trim();
      return s.length > 0;
    },
    src() {
      return `${process.env.VUE_APP_PREVIEW_URL}${this.$route.params.project_id}`;
    },
  },
  methods: {
    ...mapActions("projects", ["getProject"]),
    preserveEmptyParagraphFontSize(value) {
      const div = document.createElement("div");
      div.innerHTML = value;
      let oldFontSize = null;
      div.childNodes.forEach((n) => {
        if (n.nodeType === 1) {
          const currentNode = n.querySelector("span:last-child");
          if (currentNode) {
            const currentFontSize = currentNode.style.fontSize;
            oldFontSize = currentFontSize;
          } else {
            if (n.innerHTML === "&nbsp;") {
              n.innerHTML = `<span style="font-size: ${oldFontSize}">&nbsp;</span>`;
            }
          }
        }
      });
      return div.innerHTML;
    },
    async loadProject() {
      this.loading = true;
      await this.getProject(CryptoService.decrypt(this.$route.params.project_id + ""));
      this.text = this.project.last_message;
      this.loading = false;
    },
    async send() {
      this.sending = true;
      await $http.put(sendUrl(this.project.id), {
        message: this.preserveEmptyParagraphFontSize(this.text),
      });
      this.sending = false;
    },
    async save() {
      this.saving = true;
      await $http
        .post(saveMessageUrl(this.project.id), {
          title: this.title,
          message: this.preserveEmptyParagraphFontSize(this.text),
        })
        .then(() => {
          this.getProject(CryptoService.decrypt(this.$route.params.project_id));
          this.title = "";
        })
        .catch(() => {
          toastError("There was an error saving the messsage");
        })
        .finally(() => {
          this.saving = false;
        });
    },
    pasteMessageToEditor(message) {
      this.text = message;
      if (this.$refs.refreshingPreview) {
        this.$refs.refreshingPreview.refreshPreview(message);
      }
      this.editorKey++;
    },
  },
  mounted() {
    this.loadProject();
    this.editorKey++;
  },
};
</script>
<style lang="scss">
.send-column {
  padding-top: 200px !important;
}

.tabs {
  border-bottom: 1px solid #e3e3e3;
}

.page-header {
  color: #3e3e3e;
  font-size: 16px;
  font-weight: normal;
}
</style>
