<template>
  <v-card class="font-weight-medium">
    <div class="d-flex header-row">
      <div class="list-column name">Name</div>
      <div class="list-column date">Created at</div>
      <div class="list-column action">Actions</div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ProjectListHeader",
};
</script>

<style scoped lang="scss">
.v-card {
  font-size: 0.8rem;
  margin-top: 0.3rem;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0 3px lighten(black, 70%) !important;
}

.header-row {
  width: 100%;
}

.list-column {
  padding: 0.5rem 1rem;
  word-break: break-word;
  word-wrap: break-word;
  display: flex;
  justify-content: left;
  align-items: left;

  &.name {
    flex: 1 1 auto;
  }

  &.date {
    flex: 0 0 180px;
  }

  &.action {
    flex: 0 0 105px;
    justify-content: center;
  }
}
</style>
