export const $refreshTokenService = {
  _tokenRefreshIsInProgress: false,
  isInProgress() {
    return this._tokenRefreshIsInProgress === true;
  },
  start() {
    this._tokenRefreshIsInProgress = true;
  },
  finish() {
    this._tokenRefreshIsInProgress = false;
  },
  awaitRefresh() {
    return new Promise((resolve) => {
      var interval = setInterval(() => {
        if (!this.isInProgress()) {
          clearInterval(interval);
          resolve();
        }
      }, 50);
    });
  },
};
