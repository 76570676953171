<template>
  <v-dialog max-width="600" persistent :value="true" v-if="modal">
    <v-card :loading="loading">
      <v-card-title
        >Delete prepared message
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="cancel">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <p>Are you sure you want to delete the following message?</p>
        <div v-html="unStyledMessage" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="confirm"> Yes </v-btn>
        <v-btn color="secondary" outlined @click="cancel"> No </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { removeStyle } from "../../../services/contentService";
import { mapActions, mapState } from "vuex";

export default {
  name: "PreparedMessagesDeleteDialog",
  props: {
    message: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: this.value,
      loading: false,
    };
  },
  computed: {
    ...mapState("projects", ["project"]),
    unStyledMessage() {
      return removeStyle(this.message.message);
    },
  },
  methods: {
    ...mapActions("projects", ["deleteMessage"]),
    async confirm() {
      this.loading = true;
      await this.deleteMessage({
        projectId: this.project.id,
        message: this.message,
      });
      this.loading = false;
      this.$emit("input", false);
      this.$emit("delete");
    },
    cancel() {
      this.closeModal();
    },
    closeModal() {
      this.$emit("input", false);
    },
  },
};
</script>

<style scoped></style>
