<template>
  <v-card dark width="37vw" class="preview-message" elevation="0">
    <v-card-title> {{ message.title }} </v-card-title>
    <v-divider></v-divider>
    <v-card-text
      class="preview-message-content"
      v-html="unStyledMessage"
    ></v-card-text>
  </v-card>
</template>

<script>
import { removeStyle } from "../../../services/contentService";

export default {
  name: "PreparedMessagePreview",
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    unStyledMessage() {
      return removeStyle(this.message.message);
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-message {
  background-color: transparent;
  z-index: 1;
  max-height: 40vh;
  overflow: hidden;
  color: white;
}
.preview-message-content {
  line-height: initial;
  word-break: break-word;
  height: 100%;
  color: white !important;
  table {
    border-spacing: 0;
    width: 100%;
  }
  table thead th {
    border-bottom: 2px solid;
  }
  td,
  th {
    padding: 1.5rem 1rem;
  }
  td {
    border-bottom: 1px solid;
  }
  tfoot tr:first-child th,
  tfoot tr:last-child th,
  tbody tr:last-child th {
    border-top: 1px solid;
  }
  th,
  tr {
    text-align: left;
  }
}
</style>
