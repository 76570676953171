<template>
  <v-card>
    <div class="list-item-row d-flex">
      <div class="list-column email">{{ user.email }}</div>
      <div class="list-column date">
        {{ createdAt }}
      </div>
    </div>
  </v-card>
</template>

<script>
import moment from "moment-timezone";
export default {
  name: "UsersListItem",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    createdAt() {
      return moment
        .utc(this.user.created_at)
        .tz("Europe/Amsterdam")
        .format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  font-size: 0.8rem;
  margin-top: 0.3rem;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0 3px lighten(black, 70%) !important;
}

.v-card-text {
  font-size: 0.8rem;
  padding: 0;
}

.list-item-row {
  width: 100%;
}

.list-column {
  padding: 0.5rem 1rem;
  word-break: break-word;
  word-wrap: break-word;
  display: flex;
  justify-content: left;
  align-items: left;

  &.email {
    flex: 1 1 auto;
  }

  &.date {
    flex: 0 0 180px;
  }
}
</style>
