import { $http } from "@/services/http";
import { userListingUrl, buildSimpleQueryString } from "@/services/api";

const USER_ROWS_PER_PAGE_KEY = "userRowsPerPage";
const PAGE_SIZE = 10;

const userStore = {
  namespaced: true,
  state: {
    users: [],
    user: null,
    pagination: {
      searchText: "",
      current: 1,
      total: 1,
      pageSize:
        window.localStorage.getItem(USER_ROWS_PER_PAGE_KEY) || PAGE_SIZE,
    },
  },
  mutations: {
    setUsers: (state, payload) => (state.users = payload),
    setSearchText: (state, payload) =>
      (state.pagination.searchText = payload || ""),
    setCurrentPage: (state, payload) =>
      (state.pagination.current = parseInt(payload)),
    setPageTotal: (state, payload) =>
      (state.pagination.total = parseInt(payload)),
    setPageSize: (state, payload) => {
      window.localStorage.setItem(USER_ROWS_PER_PAGE_KEY, parseInt(payload));
      state.pagination.pageSize = parseInt(payload);
    },
  },
  actions: {
    async getUsers({ state, commit }) {
      var response = await $http.get(
        buildSimpleQueryString(userListingUrl, {
          search: state.pagination.searchText,
          page: state.pagination.current,
          per_page: state.pagination.pageSize,
        })
      );
      commit("setUsers", response.data.data);
      commit("setCurrentPage", response.data.pagination.page);
      commit("setPageTotal", response.data.pagination.total);
      commit("setPageSize", response.data.pagination.perPage);
    },

    async createUser({ dispatch }, payload) {
      await $http.post(userListingUrl, { email: payload });
      await dispatch("getUsers");
    },
  },
};

export default userStore;
