<template>
  <div class="project-url ma-6">
    <p class="my-6">Copy this url and send to presenters.</p>
    <v-text-field filled background-color="gray" :value="url" readonly label="Receiver screen">
      <template v-slot:append>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon color="primary" v-on="on" @click="copyUrl" class="ml-2 mt-n2">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copy</span>
        </v-tooltip>
      </template>
    </v-text-field>
    <v-text-field filled background-color="gray" :value="senderUrl" readonly label="Sender screen">
      <template v-slot:append>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon color="primary" v-on="on" @click="copySenderUrl" class="ml-2 mt-n2">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copy</span>
        </v-tooltip>
      </template>
    </v-text-field>
    <p class="mt-1">Scan this QR code to get the receiver screen on your device.</p>
    <qrcode-vue class="mt-8" v-if="url" :value="url" size="300" level="H" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { toastInfo } from "../../../services/responseErrors";
import { CryptoService } from "../../../services/cryptoService";
import QrcodeVue from "qrcode.vue";

export default {
  name: "ProjectUrl",
  components: { QrcodeVue },
  data() {
    return {
      url: null,
      senderUrl: null,
    };
  },
  computed: {
    ...mapState("projects", ["project"]),
  },
  methods: {
    async copyUrl() {
      await navigator.clipboard.writeText(this.url);
      toastInfo("Url copied to clipboard.");
    },
    async copySenderUrl() {
      await navigator.clipboard.writeText(this.senderUrl);
      toastInfo("Url copied to clipboard.");
    },
  },
  mounted() {
    const id = CryptoService.encrypt(this.project.id + "");
    this.url = `${process.env.VUE_APP_PREVIEW_URL}${id}`;
    this.senderUrl = `${location.protocol}//${location.host}${
      this.$router.resolve({
        name: "Project",
        params: {
          project_id: id,
        },
      }).href
    }`;
  },
};
</script>

<style scoped>
.project-url {
  min-height: 500px;
}
</style>
