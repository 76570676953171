<template>
  <div class="preview">
    <iframe
      :src="src"
      ref="preview"
      style="width: 100%; border: 0 none transparent"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "Preview",
  props: {
    src: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
  },
  methods: {
    refreshPreview(value) {
      this.$refs.preview.contentWindow.postMessage(
        value !== false ? value : this.value,
        process.env.VUE_APP_PREVIEW_URL.trim("/")
      );
    },
    resizeToAspectRatio() {
      const s = window.getComputedStyle(this.$refs.preview);
      this.$refs.preview.style.height =
        (parseInt(s.width.replace("px", "")) / 4) * 3 + "px";
    },
  },
  mounted() {
    this.resizeToAspectRatio();
    window.addEventListener("resize", this.resizeToAspectRatio);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeToAspectRatio);
  },
};
</script>

<style scoped></style>
