import { $http } from "@/services/http";
import {
  listingUrl,
  projectUrl,
  sortMessagesUrl,
  deleteMessageUrl,
  buildSimpleQueryString,
} from "@/services/api";
import { toastError } from "@/services/responseErrors";

const PROJECT_ROWS_PER_PAGE_KEY = "projectRowsPerPage";
const PAGE_SIZE = 10;

const projectStore = {
  namespaced: true,
  state: {
    projects: [],
    project: null,
    pagination: {
      searchText: "",
      current: 1,
      total: 1,
      pageSize:
        window.localStorage.getItem(PROJECT_ROWS_PER_PAGE_KEY) || PAGE_SIZE,
    },
  },
  mutations: {
    setProjects: (state, payload) => (state.projects = payload),
    setProject: (state, payload) => (state.project = payload),
    setMessages: (state, payload) => (state.project.messages = payload),
    deleteMessage: (state, payload) => {
      const index = state.project.messages.indexOf(payload);
      if (index > -1) {
        state.project.messages.splice(index, 1);
      }
    },
    setSearchText: (state, payload) =>
      (state.pagination.searchText = payload || ""),
    setCurrentPage: (state, payload) =>
      (state.pagination.current = parseInt(payload)),
    setPageTotal: (state, payload) =>
      (state.pagination.total = parseInt(payload)),
    setPageSize: (state, payload) => {
      window.localStorage.setItem(PROJECT_ROWS_PER_PAGE_KEY, parseInt(payload));
      state.pagination.pageSize = parseInt(payload);
    },
  },
  actions: {
    async getProjects({ state, commit }) {
      var response = await $http.get(
        buildSimpleQueryString(listingUrl, {
          search: state.pagination.searchText,
          page: state.pagination.current,
          perPage: state.pagination.pageSize,
        })
      );

      commit("setProjects", response.data.data);
      commit("setCurrentPage", response.data.pagination.page);
      commit("setPageTotal", response.data.pagination.total);
      commit("setPageSize", response.data.pagination.perPage);
    },
    async getProject({ commit }, payload) {
      return $http
        .get(projectUrl(payload))
        .then((response) => response.data)
        .then((data) => {
          commit("setProject", data);
        });
    },
    sortMessages({ commit }, payload) {
      commit("setMessages", payload.messages);
      const order = payload.messages.map((message, index) => {
        return {
          id: message.id,
          order: index,
        };
      });
      return $http.put(sortMessagesUrl(payload.projectId), order);
    },
    deleteMessage({ commit }, payload) {
      commit("deleteMessage", payload.message);
      return $http
        .delete(deleteMessageUrl(payload.projectId, payload.message.id))
        .catch(() => {
          toastError("There was a problem deleting the message");
        });
    },
    async createProject({ dispatch }, payload) {
      await $http.post(listingUrl, {
        name: payload,
      });
      await dispatch("getProjects");
    },
    async deleteProject({ dispatch }, payload) {
      await $http.delete(projectUrl(payload));
      await dispatch("getProjects");
    },
  },
};

export default projectStore;
