import crypto from "crypto";
export const CryptoService = {
  decrypt(text) {
    let iv = Buffer.from(process.env.VUE_APP_INIT_VECTOR);
    let encryptedText = Buffer.from(text, "hex");
    let decipher = crypto.createDecipheriv(
      "aes-256-cbc",
      Buffer.from(process.env.VUE_APP_ENCRYPTION_KEY),
      iv
    );
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  },
  encrypt(text) {
    const algorithm = "aes-256-cbc";
    const cipher = crypto.createCipheriv(
      algorithm,
      Buffer.from(process.env.VUE_APP_ENCRYPTION_KEY),
      Buffer.from(process.env.VUE_APP_INIT_VECTOR)
    );
    let encryptedData = cipher.update(Buffer.from(text));
    encryptedData += cipher.final("hex");
    return encryptedData;
  },
};
