export const listingUrl = `${process.env.VUE_APP_API_URL}api/projects`;
export const projectUrl = (projectId) =>
  `${process.env.VUE_APP_API_URL}api/projects/${projectId}`;
export const sendUrl = (projectId) =>
  `${process.env.VUE_APP_API_URL}api/projects/${projectId}/send`;
export const sendWarningUrl = (projectId) =>
  `${process.env.VUE_APP_API_URL}api/projects/${projectId}/sendWarning`;
export const clearUrl = (projectId) =>
  `${process.env.VUE_APP_API_URL}api/projects/${projectId}/clear`;
export const sortMessagesUrl = (projectId) =>
  `${process.env.VUE_APP_API_URL}api/projects/${projectId}/messages/sort`;
export const saveMessageUrl = (projectId) =>
  `${process.env.VUE_APP_API_URL}api/projects/${projectId}/messages`;
export const deleteMessageUrl = (projectId, messageId) =>
  `${process.env.VUE_APP_API_URL}api/projects/${projectId}/messages/${messageId}`;
export const userListingUrl = `${process.env.VUE_APP_API_URL}api/users`;
export const userUrl = (userId) =>
  `${process.env.VUE_APP_API_URL}api/users/${userId}`;

export const buildSimpleQueryString = function (url, parameters) {
  let paramStr = "";
  let i = 0;

  for (let key in parameters) {
    let sep = i === 0 ? "?" : "&";
    paramStr = `${paramStr}${sep}${key}=${parameters[key]}`;
    ++i;
  }

  return `${url}${paramStr}`;
};
