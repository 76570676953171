import Vue from "vue";
import VueRouter from "vue-router";
import MainLayout from "@/components/layouts/MainLayout.vue";
import NotFound from "@/components/layouts/NotFound.vue";
import Home from "@/components/pages/Home";
import Project from "@/components/pages/Project";
import AuthLayout from "../components/layouts/AuthLayout";
import AuthCallback from "../components/pages/AuthCallback";
import Users from "@/components/pages/Users";
import { refreshWhenDeprecated } from "../services/versioning";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Home" },
    component: MainLayout,
    children: [
      {
        path: "projects",
        component: Home,
        name: "Home",
        meta: { requiresAuth: true },
      },
      {
        path: "projects/:project_id",
        component: Project,
        name: "Project",
      },
      {
        path: "users",
        component: Users,
        name: "Users",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/",
    component: AuthLayout,
    children: [
      { path: "/auth", name: "AuthCallback", component: AuthCallback },
    ],
  },
  {
    path: "*",
    component: NotFound,
    name: "NotFound",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => !!record.meta.preventsDisabledAccess)) {
    next({
      name: "Project",
      params: { project_id: to.params.project_id },
    });
  } else {
    next();
  }
});

router.afterEach(() => {
  refreshWhenDeprecated();
});

export default router;
