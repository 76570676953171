const auth = {
  namespaced: true,
  state: {
    authenticated: false,
    user: {
      id: null,
      email: "",
      name: "",
    },
    PermissionBag: {},
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
  mutations: {
    login(state, payload) {
      state.authenticated = true;
      state.user.email = payload && payload.email ? payload.email : "";
      state.user.name = payload && payload.name ? payload.name : "";
      state.PermissionBag = payload.PermissionBag;
    },
    logout(state) {
      state.authenticated = false;
      state.user = {
        id: null,
        email: "",
        name: "",
      };
    },
  },
};

export default auth;
