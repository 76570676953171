import axios from "axios";
import router from "../router/index";

export const $http = axios.create();

function removeTrailingSlash(site) {
  return site.replace(/\/$/, "");
}

export const makeAccountsUrl = function (path) {
  return removeTrailingSlash(process.env.VUE_APP_ACCOUNTS_API_HOST) + path;
};

export const getInternalUrl = (params) => {
  let props = router.resolve(params);
  return `${location.protocol}//${location.host}${props.href}`;
};

export const getRequestIdentifier = (request) => {
  return request.config.method + ":" + request.config.url;
};
