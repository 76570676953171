<template>
  <div>
    <prepared-messages-header class="mb-1" />
    <draggable
      v-model="preparedMessages"
      ghost-class="ghost"
      class="field-dragger"
      handle=".drag-handle"
    >
      <v-card
        v-for="message in preparedMessages"
        :key="`message-${message.id}`"
        class="item-row my-1"
        elevation="0"
        @mouseover="showPreview(message)"
      >
        <v-tooltip
          color="rgba(0, 0, 0, 1)"
          nudge-right="15"
          transition="none"
          right
        >
          <template v-slot:activator="{ on }">
            <div class="pt-2 preview-helper" v-on="on">
              <div class="drag-handle">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon color="grey" class="mr-2 mt-n1" v-on="on">
                      mdi-drag-horizontal-variant
                    </v-icon>
                  </template>
                  <span>Drag to reorder</span>
                </v-tooltip>
              </div>
              <div class="item-title">
                <div class="font-weight-bold" :title="message.title">
                  {{ message.title || 'No title' }}
                </div>
              </div>
              <div class="font-weight-regular item-content">
                <div>
                  {{ removeHtmlTags(message.message) }}
                </div>
              </div>
              <div class="item-actions">
                <v-btn
                  small
                  icon
                  class="mb-0 mt-n1"
                  @click="pasteMessageToEditor(message.message)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>

                <v-btn
                  small
                  icon
                  class="mb-0 mt-n1"
                  color="error"
                  @click="initiateDelete(message)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
              <div class="arrow"></div>
            </div>
          </template>
          <prepared-message-preview
            :message="messageToShow"
            v-if="messageToShow"
          />
        </v-tooltip>
      </v-card>
    </draggable>
    <prepared-messages-delete-dialog
      v-if="messageToDelete && modal"
      :message="messageToDelete"
      v-model="modal"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import PreparedMessagesHeader from "@/components/pages/project-settings/PreparedMessagesHeader";
import { mapActions, mapState } from "vuex";
import PreparedMessagePreview from "./PreparedMessagePreview";
import { removeHtmlTags } from "../../../services/contentService";
import PreparedMessagesDeleteDialog from "./PreparedMessagesDeleteDialog";
export default {
  name: "PreparedMessages",
  components: {
    PreparedMessagesDeleteDialog,
    PreparedMessagePreview,
    draggable,
    PreparedMessagesHeader,
  },
  data() {
    return {
      modal: false,
      messageToDelete: null,
      messageToShow: null,
    };
  },
  computed: {
    ...mapState("projects", ["project"]),
    preparedMessages: {
      get() {
        return this.project?.messages || [];
      },
      set(value) {
        this.sortMessages({ messages: value, projectId: this.project.id });
      },
    },
  },
  methods: {
    ...mapActions("projects", ["sortMessages"]),
    initiateDelete(message) {
      this.messageToDelete = message;
      this.modal = true;
    },
    pasteMessageToEditor(message) {
      this.$emit("pasteMessageToEditor", message);
    },
    showPreview(message) {
      if (this.dragging) {
        return;
      }
      this.messageToShow = message;
    },
    removeHtmlTags(message) {
      return removeHtmlTags(message);
    },
  },
};
</script>
<style scoped lang="scss">
.item-row {
  border: 1px solid transparent;
  border-top: 1px solid #f2f2f2;
  border-left-width: 3px;
  position: relative;
  background-color: #f3f3f3;

  &:hover {
    border: 1px solid grey;
  }
  .drag-handle {
    cursor: grab;
  }
}
.preview-helper {
  position: relative;
  width: 100%;
  display: inline-flex;

  &:hover {
    .arrow {
      visibility: visible;
      border-left: 20px solid transparent;
      border-top: 20px solid transparent;
      border-left: 20px solid rgba(0, 0, 0, 0.89);
      transition: 170ms;
    }
  }
}

.arrow {
  visibility: hidden;
  position: absolute;
  transform: rotate(45deg);
  height: 0;
  width: 0;
  border-left: 0 solid transparent;
  border-top: 0 solid transparent;
  border-left: 0 solid rgba(0, 0, 0, 0.89);
  right: -37px;
  top: 10px;
}

.ghost {
  border: 1px solid #2196f3;
}

.drag-handle {
  flex: 0 0 auto;
  padding-left: 3px;
}
.item-title {
  flex: 2 0 0;
  overflow: hidden;

  & div {
    width: calc(100% - 10px);
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.item-content {
  flex: 2 0 0;
  overflow: hidden;
  & div {
    width: calc(100% - 20px);
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
