<template>
  <v-card>
    <div class="list-item-row d-flex">
      <div class="list-column name">
        <router-link
          :to="{ name: 'Project', params: { project_id: encryptProjectId(project.id) } }"
        >
          {{ project.name }}
        </router-link>
      </div>
      <div class="list-column date">
        {{ createdAt }}
      </div>
      <div class="list-column action">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="black"
              small
              v-on="on"
              :to="{
                name: 'Project',
                params: { project_id: encryptProjectId(project.id) },
              }"
              class="mr-2"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <delete-project :project="project" />
      </div>
    </div>
  </v-card>
</template>
<script>
import moment from "moment-timezone";
import { CryptoService } from "../../../services/cryptoService";
import DeleteProject from './DeleteProject';
export default {
  name: "ProjectListItem",
    components: {DeleteProject},
    props: {
    project: {
      type: Object,
      required: true,
    },
  },
  computed: {
    createdAt() {
      return moment
        .utc(this.project.created_at)
        .tz("Europe/Amsterdam")
        .format("YYYY-MM-DD HH:mm");
    },
  },
  methods: {
    encryptProjectId(projectId) {
      return CryptoService.encrypt(projectId + "");
    },
  },
};
</script>

<style scoped lang="scss">
.v-card {
  font-size: 0.8rem;
  margin-top: 0.3rem;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0 3px lighten(black, 70%) !important;
}

.v-card-text {
  font-size: 0.8rem;
  padding: 0;
}

.list-item-row {
  width: 100%;
}

.list-column {
  padding: 0.5rem 1rem;
  word-break: break-word;
  word-wrap: break-word;
  display: flex;
  justify-content: left;
  align-items: left;

  &.name {
    flex: 1 1 auto;
  }

  &.date {
    flex: 0 0 180px;
  }

  &.action {
    flex: 0 0 105px;
    justify-content: right;
  }
}
</style>
