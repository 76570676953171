<template>
  <div>
    <v-btn
      class="clear-button"
      color="#000000"
      outlined
      dark
      :disabled="clearing"
      :loading="clearing"
      @click="askClear"
    >
      <span class="font-weight-regular"> Clear</span>
    </v-btn>
    <v-dialog max-width="600" persistent :value="true" v-if="modal">
      <v-card :loading="clearing">
        <v-card-title
          >This action will clear the presenter screen
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="closeModal">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <p>Would you like to continue?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="clear"> Yes </v-btn>
          <v-btn color="secondary" outlined @click="closeModal"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { $http } from "../../../services/http";
import { clearUrl } from "../../../services/api";

export default {
  name: "ClearPresenterScreen",
  data() {
    return {
      clearing: false,
      modal: false,
    };
  },
  computed: {
    ...mapState("projects", ["project"]),
  },
  methods: {
    async clear() {
      this.clearing = true;
      await $http.put(clearUrl(this.project.id), {});
      this.clearing = false;
      this.closeModal();
    },
    askClear() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.clear-button {
  height: 36px !important;
  min-width: 50px !important;
  padding: 0 8px !important;
}
</style>
