<template>
  <v-dialog v-model="modal" persistent max-width="600">
    <v-card :loading="loading">
      <v-card-title>
        Send warning message
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="closeModal">mdi-close</v-icon>
          </template>
          <span>Close</span>
        </v-tooltip>
      </v-card-title>
      <v-card-subtitle>
        This will send a warning message to the presenter.
      </v-card-subtitle>
      <v-card-text>
        <v-textarea
          outlined
          label="Warning message"
          v-model="warningMessage"
          :counter="maxLength"
        >
        </v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" @click="confirmMessage" :disabled="!canSend"
          >Send warning message</v-btn
        >
        <v-btn color="secondary" outlined @click="closeModal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { $http } from "../../../services/http";
import { sendWarningUrl } from "../../../services/api";

export default {
  name: "SendWarningModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: this.value,
      warningMessage: "",
      loading: false,
    };
  },
  computed: {
    ...mapState("projects", ["project"]),
    hasMessage() {
      return this.warningMessage.trim().replace(/\/s+/gi, "").length > 0;
    },
    maxLength() {
      return 200;
    },
    canSend() {
      return this.hasMessage && this.warningMessage.length <= this.maxLength;
    },
  },
  methods: {
    async confirmMessage() {
      this.loading = true;
      await $http.put(sendWarningUrl(this.project.id), {
        message: this.warningMessage,
      });
      this.closeModal();
    },
    closeModal() {
      this.$emit("input", false);
    },
  },
};
</script>

<style scoped></style>
