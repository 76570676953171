export let RequestRetryService = {
  numberOfRetires: {},
  incrementNumberOfAttempts(errorResponse) {
    var identifier = getRequestIdentifier(errorResponse);
    if (
      Object.prototype.hasOwnProperty.call(this.numberOfRetires, identifier)
    ) {
      this.numberOfRetires[identifier] += 1;
    } else {
      this.numberOfRetires[identifier] = 0;
    }
  },
  tooManyAttempts(errorResponse) {
    var identifier = getRequestIdentifier(errorResponse);
    return this.numberOfRetires[identifier] >= 4;
  },
  clearAttempts(response) {
    var identifier = getRequestIdentifier(response);
    if (this.numberOfRetires[identifier]) {
      delete this.numberOfRetires[identifier];
    }
  },
};

function getRequestIdentifier(response) {
  return response.config.method + ":" + response.config.url;
}
