<template>
  <div class="pa-6">
    <project-list v-if="!loading" />
    <v-progress-linear v-else indeterminate />
  </div>
</template>

<script>
import ProjectList from "@/components/pages/projects/ProjectList";
import { mapActions } from "vuex";
export default {
  name: "Home",
  components: { ProjectList },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions("projects", ["getProjects"]),
    async loadProjects() {
      this.loading = true;
      await this.getProjects();
      this.loading = false;
    },
  },
  mounted() {
    this.loadProjects();
  },
};
</script>
