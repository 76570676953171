<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-chip color="white" class="mr-4" v-on="on">
        <span class="mr-4">{{ userName }}</span>
        <v-avatar color="primary">
          <span class="white--text">{{ initials }}</span>
        </v-avatar>
      </v-chip>
    </template>
    <v-list>
      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Sign out </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { $auth } from "../../../services/auth";
import { makeAccountsUrl } from "../../../services/http";
import { mapState } from "vuex";

export default {
  name: "UserMenu",
  computed: {
    ...mapState("auth", ["user"]),
    userName() {
      return this.user.name;
    },
    initials() {
      return this.user.name
        .split(" ")
        .map((x) => x[0])
        .join("")
        .toUpperCase();
    },
  },
  methods: {
    logout() {
      $auth.logout();

      window.location.href = makeAccountsUrl("/sso/logout");
    },
  },
};
</script>

<style scoped>

</style>
