<template>
  <div class="pa-6">
    <users-list />
  </div>
</template>

<script>
import UsersList from "@/components/pages/users/UsersList";
export default {
  name: "Users",
  components: { UsersList },
};
</script>
