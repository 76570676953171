<template>
  <v-row no-gutters class="messages-header ml-7 py-1 px-3">
    <div class="font-weight-bold text">Message title</div>
    <div class="font-weight-bold text">Text</div>
    <div class="font-weight-bold text-right">Actions</div>
  </v-row>
</template>

<script>
export default {
  name: "PreparedMessagesHeader",
};
</script>

<style scoped lang="scss">
.messages-header {
  border: 1px solid #999999;
  border-radius: 5px;
  font-weight: 16px !important;

  .text {
    flex: 2 0 0;
  }
}
</style>
