<template>
  <div class="create-project">
    <v-btn color="primary" click="showCreate" @click="showCreate">Create</v-btn>
    <v-dialog max-width="600" v-model="modal">
      <v-card :loading="loading">
        <v-card-title>
          Create Project
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="cancel" v-on="on">mdi-close</v-icon>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="projectTitle" label="Project name" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="doCreate" :disabled="!hasProjectTitle"
            >Create</v-btn
          >
          <v-btn outlined color="secondary" @click="cancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CreateProject",
  data() {
    return {
      loading: false,
      modal: false,
      projectTitle: "",
    };
  },
  computed: {
    hasProjectTitle() {
      return this.projectTitle.replace(/\s+/, "").length > 0;
    },
  },
  methods: {
    ...mapActions("projects", ["createProject"]),
    showCreate() {
      this.loading = false;
      this.projectTitle = "";
      this.modal = true;
    },
    cancel() {
      this.modal = false;
    },
    doCreate: async function () {
      this.loading = true;
      await this.createProject(this.projectTitle);
      this.loading = false;
      this.modal = false;
    },
  },
};
</script>

<style scoped>
.create-project {
  margin-left: auto;
}
</style>
