<template>
  <v-navigation-drawer permanent app width="72" clipped>
    <router-link
      class="text-decoration-none left-side-link"
      active-class="active-menu"
      :to="{ name: 'Home' }"
    >
      <div class="iconcontainer my-3 justify-center align-center">
        <v-icon class="icon"> mdi-view-list </v-icon>
        <span class="span p-0">Projects</span>
      </div>
    </router-link>
    <router-link
      class="text-decoration-none left-side-link"
      active-class="active-menu"
      :to="{ name: 'Users' }"
    >
      <div class="iconcontainer my-3 justify-center align-center">
        <v-icon class="icon"> mdi-account-multiple </v-icon>
        <span class="span p-0">Users</span>
      </div>
    </router-link>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavigationDrawer",
};
</script>

<style scoped>
.material-icons {
  width: 2rem;
}

.iconcontainer {
  margin: 0;
  width: 71px;
  height: 45px;
  text-align: center;
  display: grid;
}

.left-side-link {
  display: grid;
  justify-content: center;
}

.left-side-link .span {
  font-size: 72%;
  color: #454545;
}

.left-side-link .icon {
  color: #454545;
}

.left-side-link:hover .icon {
  color: #454545;
}

.left-side-link:hover .span {
  color: #454545;
}

.left-side-link:hover {
  background-color: rgba(41, 119, 190, 0.08);
}

.left-side-link.active-menu {
  background-color: rgba(41, 119, 190, 0.5);
  border-left: 3px solid #2977be;
}
.left-side-link.active-menu .icon {
  color: #2977be;
}

.left-side-link.active-menu .span {
  color: #2977be;
}

.left-side-link.active-menu:hover {
  background-color: rgba(41, 119, 190, 0.65);
}

.left-side-link.active-menu:hover .span {
  color: #2977be;
}

.left-side-link.active-menu:hover .icon {
  color: #2977be;
}
</style>
