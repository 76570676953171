<template>
  <div>
    <div class="d-flex">
      <h3 class="font-weight-regular page-title mt-0 mb-4">Users</h3>
      <div class="search-container ml-16">
        <v-text-field
          v-model="searchText"
          solo
          flat
          hide-details
          dense
          background-color="rgba(0,0,0,0.1)"
          placeholder="Search users"
          clearable
        />
      </div>
      <create-user />
    </div>
    <users-list-header />
    <div v-if="!loading">
      <users-list-item
        v-for="user in users"
        :key="`user-${user.id}`"
        :user="user"
      />
      <v-container fluid>
        <v-row align="center" align-content="center" justify="center">
          <v-col class="d-flex" cols="1" sm="1">
            <small
              class="d-flex justify-right"
              style="width: 100%; white-space: nowrap"
            >
              Rows per page
            </small>
          </v-col>
          <v-col class="d-flex" cols="1 sm=1">
            <v-select
              v-model="rowsPerPageSelected"
              :items="rowsPerPageItems"
              :label="undefined"
              outlined
              dense
              hide-details
            >
              <template v-slot:selection="{ item }">
                <span class="d-flex justify-end" style="width: 100%">{{
                  item
                }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col class="d-flex" cols="6" sm="3">
            <v-pagination
              v-if="pagination.total > 1"
              v-model="currentPage"
              :length="pagination.total"
              circle
              total-visible="6"
              color="secondary"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import UsersListHeader from "@/components/pages/users/UsersListHeader";
import UsersListItem from "@/components/pages/users/UsersListItem";
import CreateUser from "@/components/pages/users/CreateUser";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "UsersList",
  components: { UsersListHeader, UsersListItem, CreateUser },
  data() {
    return {
      loading: true,
      inProgress: false,
      rowsPerPageItems: [10, 25, 50],
    };
  },
  computed: {
    ...mapState("users", ["users", "pagination"]),
    searchText: {
      get() {
        return this.pagination.searchText;
      },
      set: debounce(function (value) {
        this.setSearchText(value);
        this.setCurrentPage(1);
        this.loadUsers(true);
      }, 400),
    },
    rowsPerPageSelected: {
      get() {
        return this.pagination.pageSize;
      },
      set(value) {
        this.setCurrentPage(1);
        this.setPageSize(value);
        this.loadUsers(true);
      },
    },
    currentPage: {
      get() {
        return this.pagination.current;
      },
      set(value) {
        this.setCurrentPage(value);
        this.loadUsers(true);
      },
    },
  },
  methods: {
    ...mapActions("users", ["getUsers"]),
    ...mapMutations("users", [
      "setCurrentPage",
      "setPageSize",
      "setSearchText",
    ]),
    async loadUsers(reload) {
      this.loading = reload;
      await this.getUsers();
      this.loading = false;
    },
  },
  mounted() {
    this.setCurrentPage(1);
    this.loadUsers(true);
  },
};
</script>
